import { TenantConfigurationType } from '@parashift/shared/types';
import { Model, Attribute } from './decorators';
import { BaseApiModel } from './base';

export interface TenantConfigurationPlainModel {
  id: string;
  created_at: string;
  master_data: string;
  separation_model_id: number;
  tenant_id: number;
  updated_at: string;
}

@Model({ type: TenantConfigurationType })
export class TenantConfiguration extends BaseApiModel<TenantConfigurationPlainModel> {

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  master_data: string;

  @Attribute()
  separation_model_id: number;

  @Attribute()
  tenant_id: number;

  @Attribute({ readonly: true })
  updated_at: string;

  get plainModel(): TenantConfigurationPlainModel {
    return this.toHash() as TenantConfigurationPlainModel;
  }

  set plainModel(model) {
    this.customUpdates = {
      separation_model_id: setSeparationModelId
    };

    this.setPlainModel(model);
  }
}

function setSeparationModelId(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  existingModel[key] = updatedPlainModel[key] ? Number(updatedPlainModel[key]) : null;
}
