import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceDecorator } from './decorators';
import { BaseApiService } from './base';
import { TenantConfigurationType } from '@parashift/shared/types';
import { TenantConfiguration } from '@parashift/shared/models';
import { QueryParamsService } from '../query-params.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => TenantConfiguration,
  endpointUrl: TenantConfigurationType,
})
export class TenantConfigurationService extends BaseApiService<TenantConfiguration> {
  className = TenantConfigurationService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    super(http, queryParamsService);
    this.baseUrl = this.environment.endpoints.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version;
  }
}
